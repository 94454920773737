import React from 'react';
import PropTypes from 'prop-types';

import MainPage from './components/mainPage';
import ProfilePage from './components/profilePage';
import ServicesPage from './components/servicesPage';
import ContactPage from './components/contactPage';

import PageTransitionOverlay from './components/pageTransitionOverlay';
// import Footer from './components/footer';

class NavItem {
    constructor(label, page) {
        this.label = label;
        this.page = page;
    }
}

class NavBar extends React.Component {
    constructor(props) {
        super(props);

        this.renderNavItems = this.renderNavItems.bind(this);
    }
    
    renderNavItems () {
        
        let navItems = this.props.navItems;

        const navItemsElements = navItems.map((navItem, i) => {
            let isActive = this.props.currPageIndex === i ? ' active ': '';

            return (
                <li key={'navbar-item' + navItem.label} className={'nav-item' + isActive}>
                    <div className="nav-link" onClick={() => {if (isActive === '')this.props.setPageIndex(i)}}>{navItem.label}
                        <span className="sr-only">(current)</span>
                    </div>
                </li>
                );
            }
        );
        
        return navItemsElements;
    }

    render() {
        return (
            <div className='nav-bar'>
                <nav className='navbar fixed-top navbar-expand-lg navbar-dark bg-dark'>
                    <span className="navbar-brand logo">Stella Yan Law Office</span>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#mainNavbar" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div id='mainNavbar' className='collapse navbar-collapse justify-content-end' >
                        <ul className='navbar-nav'>
                            {this.renderNavItems()}
                        </ul>
                    </div>
                </nav>
            </div>
        );
    }
}
NavBar.propTypes = {
    navItems: PropTypes.arrayOf(PropTypes.object),
    setPageIndex: PropTypes.func,
    currPageIndex: PropTypes.number,
}

class MainContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pageIndex: 0,
            pageTransitionClass: "fade-in"
        }

        this.pages = [
            new NavItem("Home", <MainPage/>),
            new NavItem("Lawyers", <ProfilePage/>),
            new NavItem("Services", <ServicesPage/>),
            new NavItem("Contact", <ContactPage/>)
        ]

        this.setPageIndex = this.setPageIndex.bind(this);
    }

    setPageIndex(index) {
        this.setState({
            pageTransitionClass: "fade-out"
        }, () => {
            window.setTimeout(
                () => {
                    this.setState({
                    pageIndex: index,
                    pageTransitionClass:"fade-in"});}, 
                400);}
        );
    }

    render() {
        const activePage = this.pages[this.state.pageIndex].page;

        return (
            <div className='main-container full-span'>
                <NavBar navItems={this.pages} setPageIndex={this.setPageIndex.bind(this)} currPageIndex={this.state.pageIndex}/>
                <PageTransitionOverlay transitionClassName={this.state.pageTransitionClass}>
                    {activePage}
                </PageTransitionOverlay>
                {/* <Footer/> */}
            </div>
        );
    }
}


export default MainContainer;