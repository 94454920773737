import React from 'react';
import PropTypes from 'prop-types';

class PageTransitionOverlay extends React.Component {
    render() {
        return (
            <div className={this.props.transitionClassName+ ' full-span'}>
                {this.props.children}
            </div>
        )
    }
}
PageTransitionOverlay.propTypes = {
    transitionClassName: PropTypes.string,
}

export default PageTransitionOverlay;