import React from 'react';

import '../styles/mainPage.css'; 

class MainPage extends React.Component {
    render() {
        return (
        <div className="main-page page">
             <div className="intro-area">
                <p className="text-main-page-intro">Since 1999, we have been providing legal services, and consultations.</p> 
                <p className="text-main-page-intro">Our legal team is fluent in English, Mandarin, Cantonese, and Shanghainese.</p>
             </div>
        </div>
        );
    }
}

export default MainPage;