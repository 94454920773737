import React from 'react';

import '../styles/contactPage.css'; 

class ContactPage extends React.Component {
    render() {

        let leftStyle={display:'inline-block'};
        let rightStyle={display:'inline-block',float:'right',textAlign:'right'};

        return (
        <div className="contact-page page">
            <div className="container-fluid contact-info">
                <div className="row">
                    <div className="col">
                        <div>
                        <h2>Location:</h2>
                        <div style={leftStyle}>
                            <p><b>Address:</b><br/><br/><br/></p>
                        </div>
                        <div style={rightStyle}>
                            <p>#2400-8888 Odlin Crescent<br/>Richmond, BC<br/>V6X 3Z8</p>
                        </div>
                        </div>
                        <iframe title="Office Location Map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2607.871718105154!2d-123.1270887!3d49.1840208!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x548675241c90b839%3A0xc795d1db5263f8e5!2sStella+Yan+Law+Office!5e0!3m2!1sen!2sca!4v1552796232733" frameborder="0" style={{border:'0', width:'100%', height:'230px'}} allowfullscreen></iframe>
                        <hr className="my-4"/>
                    </div>
                    <div className="col">
                        <h2>Business Hours:</h2>
                        <div style={leftStyle}>
                            <p>
                                <b>Mon - Fri:</b><br/>
                                <b>Sat - Sun & Holidays:</b>
                            </p>
                        </div>
                        <div style={rightStyle}>
                            <p>
                                9:00 - 17:30<br/>
                                Closed
                            </p>
                        </div>
                        <hr className="my-4"/>
                        <h2>Contact:</h2>
                        <div style={leftStyle}>
                            <p>
                                <b>Email:</b><br/>
                                <b>Tel:</b><br/>
                                <b>Fax:</b>
                            </p>
                        </div>
                        <div style={rightStyle}>
                            <p>
                                lawoffice@stellayan.com<br/>
                                604-233-1288<br/>
                                604-233-1088
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
    }
}

export default ContactPage;