import React from 'react';

import '../styles/servicesPage.css'; 

class ServicesPage extends React.Component {
    constructor(props) {
        super(props);

        this.services = [
            "Real Estate Transactions",
            "Business Transfers",
            "Company Incorporation",
            "Power of Attorney",
            "Notarizations/Authentication",
            "Wills/Estates"
        ];

        this.renderServices = this.renderServices.bind(this);
    }
    
    renderServices(services) {
        var serviceLabels = services.map((service, index) => {
            return (<p className="service-text">{service}</p>);
        });

        let colLen = serviceLabels.length;
        var serviceCols = [];

        while (serviceLabels.length !== 0) {
            let colServiceRows = serviceLabels.splice(0, colLen);
            serviceCols.push(<div className="col">{colServiceRows}</div>);
        }

        return (<div className="row">{serviceCols}</div>);
    }

    render() {
        
        return (
            <div className="services-page page">
                <div className="container-fluid jumbotron ">
                    <h2>Services</h2>
                    <hr/>
                    {this.renderServices(this.services)}
                </div>
            </div>
        );
    }
}

export default ServicesPage;