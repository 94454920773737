import React from 'react';
import PropTypes from 'prop-types';

import '../styles/profilePage.css'; 

class ProfileInfo {
    constructor(name, portraitUrl, qualifications) {
        this.name = name;
        this.portraitUrl = portraitUrl;
        this.qualifications = qualifications;
    }
}

class ProfileArrows extends React.Component {
    render() {
        return (
            <div className="profile-section">
                <div className="portrait-frame">
                    <div className="portrait" style={{backgroundImage:this.props.profileInfo.portraitUrl, backgroundSize:'13em'}}/>
                </div>
                <div className="profile-chevron-wrapper">
                    <div className="rectangle">
                        <p className="profile-text name">{this.props.profileInfo.name}</p>
                        <p className="profile-text license">{this.props.profileInfo.qualifications}</p>
                    </div>
                    <div className="triangle"/>
                    <div className="bar"/>
                </div>
            </div>
        );
    }
}
ProfileArrows.propTypes = {
    profileInfo: PropTypes.object
}

class ProfilePage extends React.Component {
    constructor(props) {
        super(props);

        this.profiles = [];
        this.profiles.push(new ProfileInfo("Stella Yan", "url('/img/stella.jpg')", "LL.B (Hons) U.K.\nBarrister, Solicitor & Notary"));
        this.profiles.push(new ProfileInfo("Irene Siu", "url('/img/irene.jpg')", "LL.B (Hons) H.K.U\nBarrister, Solicitor & Notary"));

        this.renderProfiles = this.renderProfiles.bind(this);
    }

    renderProfiles() {
        return this.profiles.map((profile) => (<ProfileArrows profileInfo={profile}/>));
    }

    render() {
        return (
        <div className="profile-page page">
            <div className="d-flex flex-column h-100 justify-content-center">
                <div className="row d-flex justify-content-center">
                    {this.renderProfiles()}
                </div>
            </div>
        </div>
        );
    }
}

export default ProfilePage;